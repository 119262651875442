import axios from "axios";

const AXIOS_REQUEST_CONFIG = {
  development: {
    baseURL: "http://localhost:7071/api",
  },
  production: {
    baseURL: "https://ifuelsmart.azurewebsites.net/api",
    params: {
      code: "6R1QfHKwGzdomXiwjASFBJ7s3l4Ygyljb20KkASLofMkZnUF7dMnHA==",
      clientId: "default",
    },
  },
}[process.env.REACT_APP_ENV];

// Javascript API
const JAVASCRIPT_API_AXIOS_REQUEST_CONFIG = {
  development: {
    baseURL: "http://localhost:7072/api",
  },
  production: {
    baseURL: "https://ifuelsmart-typescript-api.azurewebsites.net/api",
    params: {
      code: "RSLIaK2kHvPg7ikB0oahHB8Z5vV4H7WcPfeF_HybK2etAzFuAh1BRw==",
      clientId: "default",
    },
  },
}[process.env.REACT_APP_ENV];

const apiAxios = axios.create(AXIOS_REQUEST_CONFIG);

const javascriptApiAxios = axios.create(JAVASCRIPT_API_AXIOS_REQUEST_CONFIG);

// API - Fuel Plan
const getFuelPlan = ({ customer, origin, destination, via, tractorFuel, tractorFuelCapacity, MPG }) =>
  apiAxios.get("/ifuels", {
    params: {
      rtype: "fuel",
      cust: customer,
      orig: origin,
      dest: destination,
      via: JSON.stringify(via),
      tanklvl: tractorFuel,
      fuel_capacity: tractorFuelCapacity,
      MPG,
    },
  });

// API - Utils
const sendEmail = ({ to, subject, html }) => javascriptApiAxios.post("/send-email", { to, subject, html });
const sendMessage = (config) => javascriptApiAxios.post("/send-message", config);

const storeFuelPlan = (customer, unitNumber, fuelPlan) => {
  return apiAxios.post("/fuel-plans", { customer, unitNumber, ...fuelPlan });
};

// API - Fuel Locations
const getFuelLocations = (companyId) => javascriptApiAxios.get(`/customers/${companyId}/fuel-stations`);

const updateFuelLocation = (companyId, id, status) =>
  apiAxios.get(`/ifs_uFuelStation`, {
    params: {
      customer: companyId,
      id,
      switch: status,
    },
  });

const getTractorsForDropdown = (customerCode) => {
  return javascriptApiAxios.get(`/customers/${customerCode}/tractors-samsara`);
};

const getFuelLevelForDropdownTractors = (customerId) => {
  return javascriptApiAxios.get(`/customers/${customerId}/tractors/fuel-level`);
};

const getTractorsForTable = (customerId) => {
  return javascriptApiAxios.get(`/tractors?customerCode=${customerId}`);
};

// creates and updates tractor
const createTractor = (customer, tractorData) => {
  return javascriptApiAxios.post(`/tractors`, { customer, ...tractorData });
};

const deleteTractor = (customer, id) => {
  return javascriptApiAxios.delete(`/tractors/${id}`);
};

const getCustomerFuelPlanComplience = (customerId, startDate, endDate) => {
  return javascriptApiAxios.get("get-customer-fuel-stop-compliance", {
    params: {
      customerCode: customerId,
      startDate,
      endDate,
    },
  });
};

function queueFuelPlan(customer, unitNumber, fuelPlan) {
  return javascriptApiAxios.post(`/queue-fuel-plan`, { customer, unitNumber, ...fuelPlan });
}

/**
 * @typedef {import("../types/index").FuelSolution} FuelSolution
 */

/**
 * Fuel Solutions
 * @param {string} companyCode
 * @returns Promise<FuelSolution[]>
 */
export async function getFuelSolutions(companyCode) {
  const fuelSolutionsResponse = await javascriptApiAxios.get(`/${companyCode}/fuel-solutions`);
  return fuelSolutionsResponse.data.data.fuelSolutions;
}

export async function deleteFuelSolution({ companyCode, fuelSolutionId }) {
  await javascriptApiAxios.delete(`/${companyCode}/fuel-solutions/${fuelSolutionId}`);
}

export async function validateFuelSolutionPassword({ password, companyCode }) {
  await javascriptApiAxios.post(`/${companyCode}/fuel-solutions/validate-password`, { password });
}

export {
  createTractor,
  deleteTractor,
  getCustomerFuelPlanComplience,
  getFuelLevelForDropdownTractors,
  getFuelLocations,
  getFuelPlan,
  getTractorsForDropdown,
  getTractorsForTable,
  queueFuelPlan,
  sendMessage,
  storeFuelPlan,
  updateFuelLocation,
  sendEmail,
};
